.overlay-card {
  a {
    display: block;
    height: 100%;
    div {
      height: 100%;
    }
  }
  lottus-promo-link-portalverse {
    display: block;
    height: 100%;
    .container-promolink {
      height: 100%;
    }
  }
}