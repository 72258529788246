
@font-face {
  font-family: "Bunday Sans Up";
  font-weight: 100;
  font-style: normal;
  font-display: swap;
  src: url("./BundaySans-HairUp.otf") format("opentype");
}
@font-face {
  font-family: "Bunday Sans Up";
  font-weight: 100;
  font-style: italic;
  font-display: swap;
  src: url("./BundaySans-HairUpIt.otf") format("opentype");
}
@font-face {
  font-family: "Bunday Sans Up";
  font-weight: 200;
  font-style: normal;
  font-display: swap;
  src: url("./BundaySans-ThinUp.otf") format("opentype");
}
@font-face {
  font-family: "Bunday Sans Up";
  font-weight: 200;
  font-style: italic;
  font-display: swap;
  src: url("./BundaySans-ThinUpIt.otf") format("opentype");
}
@font-face {
  font-family: "Bunday Sans Up";
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("./BundaySans-LightUp.otf") format("opentype");
}
@font-face {
  font-family: "Bunday Sans Up";
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  src: url("./BundaySans-LightUpIt.otf") format("opentype");
}
@font-face {
  font-family: "Bunday Sans Up";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("./BundaySans-SemiLightUp.otf") format("opentype");
}
@font-face {
  font-family: "Bunday Sans Up";
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url("./BundaySans-SemiLightUpIt.otf") format("opentype");
}
@font-face {
  font-family: "Bunday Sans Up";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("./BundaySans-RegularUp.otf") format("opentype");
}
@font-face {
  font-family: "Bunday Sans Up";
  font-weight: 500;
  font-style: italic;
  font-display: swap;
  src: url("./BundaySans-RegularUpIt.otf") format("opentype");
}
@font-face {
  font-family: "Bunday Sans Up";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url("./BundaySans-SemiBoldUp.otf") format("opentype");
}
@font-face {
  font-family: "Bunday Sans Up";
  font-weight: 600;
  font-style: italic;
  font-display: swap;
  src: url("./BundaySans-SemiBoldUpIt.otf") format("opentype");
}
@font-face {
  font-family: "Bunday Sans Up";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("./BundaySans-BoldUp.otf") format("opentype");
}
@font-face {
  font-family: "Bunday Sans Up";
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url("./BundaySans-BoldUpIt.otf") format("opentype");
}
@font-face {
  font-family: "Bunday Sans Up";
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  src: url("./BundaySans-ExtraBoldUp.otf") format("opentype");
}
@font-face {
  font-family: "Bunday Sans Up";
  font-weight: 800;
  font-style: italic;
  font-display: swap;
  src: url("./BundaySans-ExtraBoldUpIt.otf") format("opentype");
}
@font-face {
  font-family: "Bunday Sans Up";
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url("./BundaySans-HeavyUp.otf") format("opentype");
}