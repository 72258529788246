div {
  lottus-select {
    .select-container {
      .selectbody {
        .label {
          .titleselect {
            @apply font-texts font-normal  #{!important};
          }
        }
      }
      .options {
        .content {
          .option.-active {
            @apply text-primary-500 #{!important};
          }
        }
      }
    }
  }
}

.select-container .options {
  max-height: 9rem !important;

}