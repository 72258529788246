div {
  lottus-accordion {
    div.accordion-component {
      div.container {
        @apply px-4 py-6;
        max-width: 100% !important;
        @media (min-width: 1024px) {
          max-width: 100% !important;
        }
        markdown {
          * {
            @apply font-texts font-normal;
          }
          table {
            tr {
              td {
                a {
                  @apply font-bold w-p:break-all #{!important}
                }
                @apply w-p:px-4 px-7 w-p:py-6 py-9 font-bold align-top #{!important}
              }
              @apply first:bg-surface-50 first:border-b first:border-surface-300 #{!important}
            }
          @apply block overflow-x-auto mb-5 #{!important}
        }
        }        
      }
      div.heading {
        div.left {
          div.title {
            @apply font-texts #{!important};
            &.-bold {
              @apply font-texts #{!important};

            }
          }
        }
      }
    }
  }
}