div.wrapperNumbers {
  lottus-numbers-portalverse {
    div.container-numbers {
      div.content {
        div.head {
          div.firsticon {
            span {
              font-size: 24px !important;
            }
          }
          div.prefix {
            span {
              font-size: 40px !important;
            }
          }
          div.suffix {
            span {
              font-size: 40px !important;
            }
          }
        }
      }
    }
  }
}