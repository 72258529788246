@import '../fonts/local-fonts.css';
@import './fonts.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import './utils.scss';

@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  src: url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v156/kJEhBvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oFsLjBuVY.woff2) format('woff2');
}

// TODO: Delete "old-components" import when components migration is completed
@import '../old-components/index.scss';
@import '../components/index.scss';

html {
  scroll-behavior: smooth !important;
}

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  user-select: none;

  -webkit-font-smoothing: antialiased;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.material-icons {
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  user-select: none;

  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.Twilio-RootContainer * {
  font-size: 16px !important;
}

button {
  span {
    user-select: none;
    font-family: "Nunito Sans" !important;
  }
}

lottus-accordion {
  p {
    font-size: 16px;
  }
}

.outstandingmoduledescription {
  width: 100%;
}

.material-icons {
  user-select: none;
}