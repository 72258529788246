lottus-rich-text {
  > markdown {
    h1, h2, h3, h4, h5, h6 {
      @apply font-headings mb-6 #{!important};
    }
    h1, h2, h3 {
      @apply font-bold #{!important};
    }
    h1, h2 {
      @apply leading-9 w-d:leading-16 w-p:text-7.5 w-t:text-8.5 w-d:text-13 #{!important};
    }
    h3 {
      @apply leading-8 w-d:leading-12 w-p:text-6 w-t:text-8.5 text-10 #{!important};
    }
    h4, h5, h6 {
      @apply text-base font-semibold leading-5 #{!important};
    }
    ul li {
      @apply list-disc #{!important};
    }
    table {
        tr {
          td {
            a {
              @apply font-bold w-p:break-all #{!important}
            }
            @apply w-p:px-4 px-7 w-p:py-6 py-9 font-bold align-top #{!important}
          }
          @apply first:bg-surface-50 first:border-b first:border-surface-300 #{!important}
        }
      @apply block overflow-x-auto mb-5 #{!important}
    }
  }
}

div {
  &.dark {
    > lottus-rich-text {
      > markdown{
        * {
          color: white;
          @apply font-texts font-medium text-base leading-5;
        }

        p {
          b {
            font-weight: 700;
          }
        }
        ul {
          list-style-type: circle;
        }
      }
    }
  }
  &.light {
    > lottus-rich-text {
      > markdown{
        * {
          color: black;
          @apply font-texts font-medium text-base leading-5;
        }

        p {
          b {
            font-weight: 700;
          }
        }
        ul {
          list-style-type: circle;
        }
      }
    }
  }
  &.transparent {
    > lottus-rich-text {
      > markdown{
        * {
          color: black;
          @apply font-texts font-medium text-base leading-5;
        }

        p {
          b {
            font-weight: 700;
          }
        }
        ul {
          list-style-type: circle;
        }
      }
    }
  }
}