.cardProgram {
  div {
    lottus-link-icons {
      .linkicons-content {
        .text {
          * {
            font-weight: bold;
          }
        }
      }
    }
  }
}