lottus-button {
  &.dark {
    button {
      background-color: black !important;
      color: white !important;
      border: 1px solid black !important;
      &:hover {
        background-color: #F2F2F2 !important;
        color: black !important;
        border: 1px solid black;
      }
    }
  }
  &.darkOutlined {
    button {
      background-color: transparent !important;
      color: white !important;
      border: 1px solid white !important;
      &:hover {
        background-color: black !important;
        border: 1px solid black !important;
      }
    }
  }
}