section {
  lottus-outstanding-module-portalverse {
    section {
      div.outstandingmoduledescription {
        // color: white;
        span {
          @apply font-headings #{!important};
          font-size: 22px !important;
          font-weight: 600 !important;
          line-height: 28px !important;
          @media (min-width: 600px) and (max-width: 1023px) {
            font-size: 16px !important;
            line-height: 20px !important;
          }
          @media (max-width: 599px) {
            font-size: 16px !important;
            line-height: 20px !important;
          }
        }
        
        markdown {
          * {
            font-family: Nunito Sans !important;
            font-size: 16px !important;
            font-weight: 400 !important;
            @media (min-width: 600px) and (max-width: 1023px) {
              font-size: 14px !important;
              line-height: 17px !important;
            }
            
            @media (max-width: 599px) {
              font-size: 15px !important;
              line-height: 18px !important;
            }
            
          }
          h1, h2, h3, h4, h5, h6 {
            @apply font-headings mb-6 #{!important};
          }
          h1, h2, h3 {
            @apply font-bold #{!important};
          }
          h1, h2 {
            @apply leading-9 w-d:leading-16 w-p:text-7.5 w-t:text-8.5 w-d:text-13 #{!important};
          }
          h3 {
            @apply leading-8 w-d:leading-12 w-p:text-6 w-t:text-8.5 text-10 #{!important};
          }
          h4, h5, h6 {
            @apply text-base font-semibold leading-5 #{!important};
          }
          ul li {
            @apply list-disc #{!important};
          }
        }
      }
    }
  }
}