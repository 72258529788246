div {
  lottus-input {
    div.input-wrapper {
      div.field {
        div.inputbody {
          input {
            width: 100% !important;
            padding-top: 2.25rem !important;
            &:focus {
              height: 2.625rem !important;
              @apply border-primary-500 #{!important};
              
            }
          }
          input:focus + label {
            @apply text-primary-500 #{!important};
            
          }
          label {
            @apply font-texts font-normal  #{!important};
          }
        }
      }
      @apply text-xs font-texts font-normal #{!important};
    }
  }
}